<template>
  <v-bottom-sheet
    v-model="sheet"
    lazy
    persistent
  >
    <v-card>
      <v-container fluid>
        <v-layout
          row
          wrap
        >
          <v-flex xs9>
            <div class="title orange--text">
              Dealer Certificate Program
            </div>
          </v-flex>
          <v-flex xs3>
            <v-select
              v-model="language"
              :items="languages"
            />
          </v-flex>
          <v-flex xs12>
            <v-divider />
          </v-flex>
          <v-flex xs12>
            <v-card class="mx-auto elevation-0">
              <v-card-text
                v-if="step > 0 && step + 1 < qna.length"
                class="title font-weight-regular justify-space-between"
              >
                <span>{{ malay ? 'Soalan' : 'Question' }} &nbsp;&nbsp;</span>
                <v-avatar
                  color="primary"
                  class="subheading white--text"
                  size="23"
                  v-text="step"
                />
              </v-card-text>
              <v-window v-model="step">
                <v-window-item
                  v-for="item in qna"
                  :key="`item-${item.qid}`"
                >
                  <v-card-text
                    v-if="item.slideMalay && item.slideEnglish"
                  >
                    <v-alert
                      :value="true"
                      type="warning"
                      icon="info"
                      outline
                    >
                      <span v-if="language == 'Malay'">
                        <span>Sila baca Modul sebelum menjawab soalan.</span>
                        <v-spacer />
                        <v-btn
                          :href="item.slideMalay"
                          target="_blank"
                          fab
                          small
                        >
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                        <span>DCP Modul Malay</span>
                      </span>
                      <span v-else>
                        <span>Please read the Module before answering the questions.</span>
                        <v-spacer />
                        <v-btn
                          :href="item.slideEnglish"
                          target="_blank"
                          fab
                          small
                        >
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                        <span>DCP Module English</span>
                      </span>
                    </v-alert>
                  </v-card-text>
                  <v-card-text v-if="item.type === 'choice'">
                    <span>{{ malay ? item.malay : item.english }}</span>
                    <v-radio-group
                      v-model="item.answer"
                      :mandatory="false"
                    >
                      <v-radio
                        v-for="choice in item.choices"
                        :key="choice"
                        :label="choice"
                        :value="choice"
                      />
                    </v-radio-group>
                  </v-card-text>
                  <v-card-text v-else>
                    <v-sheet :color="resultColor">
                      <v-card-text>
                        <p>{{ malay ? item.malay : item.english }}</p>
                      </v-card-text>
                    </v-sheet>
                  </v-card-text>
                  <v-alert
                    :value="alert"
                    type="error"
                    transition="scale-transition"
                  >
                    {{ alertMessage }}
                  </v-alert>

                  <v-divider />

                  <v-card-actions>
                    <v-btn
                      :disabled="step === 0 || step + 1 === qna.length"
                      flat
                      @click="back()"
                    >
                      Back
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      v-if="step + 2 < qna.length"
                      color="primary"
                      @click="next(item)"
                    >
                      Next
                    </v-btn>
                    <v-btn
                      v-else-if="step + 1 < qna.length"
                      color="primary"
                      @click="submit(item)"
                    >
                      Submit
                    </v-btn>
                    <v-btn
                      v-else
                      color="primary"
                      @click="closeOrRetry()"
                    >
                      {{ buttonText }}
                    </v-btn>
                  </v-card-actions>
                </v-window-item>
              </v-window>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'

export default {
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    language: 'English',
    languages: ['English', 'Malay'],
    qna: [],
    sheet: false,
    step: 0,
    alert: false,
    alertMessage: null,
    buttonText: null,
    resultColor: 'info',
  }),

  computed: {
    malay: function () {
      return this.language === 'Malay'
    },
  },

  mounted: function () {
    this.sheet = this.value
  },

  watch: {
    value: function (val) {
      this.sheet = val
      if (val) {
        this.$rest.get('getDcpQuestions.php', createGetParams({})).then(function (response) {
          this.qna = response.data
        }.bind(this)).catch(error => {
          console.log(error.message)
        })
      }
    },
  },

  methods: {
    closeOrRetry: function () {
      if (this.result.pass) {
        this.sheet = !this.sheet
        this.$emit('update:value', this.sheet)
      } else {
        this.step = 1
        this.resultColor = 'info'
      }
    },
    back: function () {
      this.alert = false
      this.step--
    },
    next: function (item) {
      if (item.type === 'choice' && !item.answer) {
        this.alertMessage = 'Please select an answer.'
        this.alert = true
      } else {
        this.step++
        this.alert = false
      }
    },
    submit: async function (item) {
      if (item.type === 'choice' && !item.answer) {
        this.alertMessage = 'Please select an answer.'
        this.alert = true
        return
      }

      const answers = []

      this.alert = false
      this.qna.forEach(question => {
        question.type === 'choice' && answers.push({
          qid: question.qid,
          answer: question.answer,
          tag: question.tag,
        })
      })

      try {
        const params = createPostParams({
          action: 'answerDcp',
          payload: {
            answers,
          },
        })

        const response = await this.$rest.post('postAction.php', params)
        this.result = response.data
        if (this.result.pass) {
          this.buttonText = 'Close'
          this.resultColor = 'success'
        } else {
          this.buttonText = 'Retry'
          this.resultColor = 'warning'
        }
        this.qna[this.qna.length - 1].malay = this.result.malay
        this.qna[this.qna.length - 1].english = this.result.english
        this.step++
        this.alert = false
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.alertMessage = message
        this.alert = true
      }
    },
  },
}
</script>
